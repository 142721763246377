import {toast} from "vue3-toastify";
import "vue3-toastify/dist/index.css";


export const kodiEvents = new EventTarget();

// Function to trigger an event
export function emitEvent(eventData) {
    // Create a new custom event
    const event = new CustomEvent('kodi', {detail: eventData});

    // Dispatch the event to all registered listeners
    kodiEvents.dispatchEvent(event);
}

export function KodiWebsocket() {
    let ws = null;
    let ws_connected = false;
    let reconnect = true
    let player_id = null;
    let get_info_timeout = null;

    function set_toast(message, level) {
        toast(message, {
            "theme": "dark",
            "type": level,
            "autoClose": 2000,
            "transition": "flip",
            "dangerouslyHTMLString": true
        })
    }

    function closeConnection() {
        return new Promise((resolve, reject) => {
            if (ws) {
                reconnect = false
                ws.close();
                ws = null;
                ws_connected = false;
                console.log('Player disconnected by user request')
            }
            resolve()
        })
    }

    function get_player_id() {
        if (ws_connected === false) return
        return new Promise((resolve, reject) => {
            try {
                ws.send(JSON.stringify({
                        "jsonrpc": "2.0",
                        "method": "Player.GetActivePlayers",
                        "id": 1
                    }
                ))
            } catch (e) {
                ws.close()
            }
            resolve()
        })
    }

    function ping() {
        if (ws_connected === false) return
        return new Promise((resolve, reject) => {
            try {
                ws.send(JSON.stringify({
                        "jsonrpc": "2.0",
                        "method": "JSONRPC.Ping",
                        "id": 1
                    }
                ))
            } catch (e) {
                ws.close()
            }
            resolve()
        })
    }

    function play(url) {
        if (ws_connected === false) return
        return new Promise((resolve, reject) => {
            try {
                ws.send(JSON.stringify({
                    "jsonrpc": "2.0",
                    "id": "1",
                    "method": "Player.Open",
                    "params": {"item": {"file": url}},
                }))
            } catch (e) {
                ws.close()
            }
            resolve()
        })
    }

    function play_and_pause() {
        if (ws_connected === false) return
        return new Promise((resolve, reject) => {
            try {
                ws.send(JSON.stringify({
                    "jsonrpc": "2.0",
                    "id": "1",
                    "method": "Player.PlayPause",
                    "params": {"playerid": player_id}
                }))
            } catch (e) {
                ws.close()
            }
            resolve()
        })
    }

    function stop() {
        if (ws_connected === false) return
        return new Promise((resolve, reject) => {
            try {
                ws.send(JSON.stringify({
                    "jsonrpc": "2.0",
                    "id": "1",
                    "method": "Player.Stop",
                    "params": {"playerid": player_id}
                }))
            } catch (e) {
                ws.close()
            }
            resolve()
        })
    }

    function set_position(percentage) {
        if (ws_connected === false) return
        return new Promise((resolve, reject) => {
            try {
                ws.send(JSON.stringify({
                    "jsonrpc": "2.0",
                    "method": "Player.Seek",
                    "id": 1,
                    "params": {
                        "playerid": player_id,
                        "value": {"percentage": percentage}
                    }
                }))
            } catch (e) {
                ws.close()
            }
            resolve()
        })
    }

    function get_playback_info() {
        if (ws_connected === false) return
        return new Promise((resolve, reject) => {
            try {
                ws.send(JSON.stringify({
                    "jsonrpc": "2.0",
                    "method": "Player.GetProperties",
                    "params": {
                        "playerid": player_id,
                        "properties": [
                            "time",
                            "totaltime",
                            "percentage"
                        ]
                    },
                    "id": "1"
                }))
            } catch (e) {
                ws.close()
            }
            resolve()
        })
    }

    function get_streams() {
        if (ws_connected === false) return
        return new Promise((resolve, reject) => {
            try {
                ws.send(JSON.stringify({
                    "jsonrpc": "2.0",
                    "method": "Player.GetItem",
                    "params": {
                        "playerid": player_id,
                        "properties": ["streamdetails"]
                    },
                    "id": 1
                }))
            } catch (e) {
                ws.close()
            }
        })
    }

    function set_volume(volume) {
        if (ws_connected === false) return
        return new Promise((resolve, reject) => {
            try {
                ws.send(JSON.stringify({
                    "jsonrpc": "2.0",
                    "method": "Application.SetVolume",
                    "params": {"volume": volume},
                    "id": 1
                }))
            } catch (e) {
                ws.close()
            }
            resolve()
        })
    }

    function get_volume() {
        if (ws_connected === false) return
        return new Promise((resolve, reject) => {
            try {
                ws.send(JSON.stringify({
                    "jsonrpc": "2.0",
                    "method": "Application.GetProperties",
                    "params": {"properties": ["volume"]},
                    "id": 1
                }))
            } catch (e) {
                ws.close()
            }
            resolve()
        })
    }

    function get_currently_playing() {
        if (ws_connected === false) return
        return new Promise((resolve, reject) => {
            try {
                ws.send(JSON.stringify({
                    "jsonrpc": "2.0",
                    "method": "Player.GetItem",
                    "params": {"properties": ["file"], "playerid": player_id},
                    "id": "VideoGetItem"
                }))
            } catch (e) {
                ws.close()
            }
            resolve()
        })
    }

    function set_next_audio_track(index) {
        if (ws_connected === false) return
        return new Promise((resolve, reject) => {
            try {
                ws.send(JSON.stringify({
                    "jsonrpc": "2.0",
                    "id": 1,
                    "method": "Player.SetAudioStream",
                    "params": {"playerid": player_id, "stream": index}
                }))
            } catch (e) {
                ws.close()
            }
            resolve()
        })
    }

    function set_next_subtitle_track(index, enable) {
        if (ws_connected === false) return
        return new Promise((resolve, reject) => {
            try {
                ws.send(JSON.stringify({
                    "jsonrpc": "2.0",
                    "id": 1,
                    "method": "Player.SetSubtitle",
                    "params": {"playerid": player_id, "subtitle": index}
                }))
                setTimeout(function () {
                    ws.send(JSON.stringify({
                        "jsonrpc": "2.0",
                        "id": 1,
                        "method": "Player.SetSubtitle",
                        "params": {"playerid": player_id, "subtitle": enable ? 'on' : 'off'}
                    }))
                }, 300)
            } catch (e) {
                ws.close()
            }
            resolve()
        })
    }

    function get_player_stats() {
        if (ws_connected === false) return
        get_playback_info()
        ping()
        get_info_timeout = setTimeout(function () {
            get_player_stats()
        }, 1000)
    }

    function connect_ws(player) {
        const websocketURL = 'ws://' + player.username + ":" + player.password + "@" + player.ip_address + ":9090/jsonrpc"
        ws = new WebSocket(websocketURL);

        // Set up a timeout function
        let timeout = setTimeout(function () {
            // If WebSocket is still connecting after the timeout, close it
            if (ws) {
                if (ws.readyState === WebSocket.CONNECTING) {
                    closeConnection().then(() => {
                        const message = "<div><h4>" + player.name + "</h4><p>Failed to connect</p></div>"
                        set_toast(message, 'warn')
                    })
                }
            }
        }, 10000);

        ws.onopen = async () => {
            clearTimeout(timeout)
            ws.send('Connect')
            ws_connected = true;
            await get_player_id()
            get_player_stats()
            setTimeout(async function () {
                await get_streams()
            }, 1000)

        };

        ws.onmessage = async (event) => {
            if (event.data === undefined) return;
            const data = JSON.parse(event.data)
            if (data && data.result && data.result.length > 0 && data.result[0].playerid !== undefined) {
                player_id = data.result[0].playerid;
                return;
            }

            if (data.result && data.result.item && data.result.item.streamdetails) {
                console.log(data.result.item.streamdetails)
                emitEvent({'player': 'stream_details', 'details': data.result.item.streamdetails})
            }

            if (data.hasOwnProperty('result')) {
                if (!Array.isArray(data.result)) {
                    try {
                        if (data.result === 'pong') {
                            emitEvent({'player': 'last_seen', 'date': Date.now()})
                        } else {
                            emitEvent({'player': 'media_info', 'media_info': data.result})
                        }
                    } catch (e) {
                    }
                }
            }

            if (data.hasOwnProperty('method')) {
                switch (data.method) {
                    case 'Playlist.OnClear':
                        emitEvent({'player': 'stop'});
                        break;

                    case 'Player.OnAVStart':
                        emitEvent({'player': 'play'});
                        await get_streams()
                        break;

                    case 'Player.OnPause':
                        emitEvent({'player': 'pause'});
                        break;

                    case 'Player.OnResume':
                        emitEvent({'player': 'play'});
                        await get_streams()
                        break;

                    default:
                        console.log(data)
                        break;
                }
            }
        }

        ws.onclose = (e) => {
            clearTimeout(get_info_timeout)
            if (reconnect) {
                setTimeout(function () {
                    connect_ws(player)
                }, 3000)
            }
        }

        ws.onerror = (error) => {
            clearTimeout(get_info_timeout)
            if (reconnect) {
                setTimeout(function () {
                    connect_ws(player)
                }, 3000)
            }
        }
    }

    return {
        connect_ws,
        closeConnection,
        play,
        play_and_pause,
        stop,
        set_position,
        get_playback_info,
        get_streams,
        set_volume,
        get_volume,
        get_currently_playing,
        set_next_audio_track,
        set_next_subtitle_track
    };
}
