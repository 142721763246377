import {createPinia, defineStore} from 'pinia'

export const pinia = createPinia()

export const useStore = defineStore({
    id: 'store',
    state: () => ({
        players_updated: 0,  // When ever there's a change in the database
        selected_player: null,  // Currently selected player for interfacing
        show_player_component: false,  // Do we show or hide the player component
        has_players: false,  // Bool to check if we have players
        live_tv_to_kodi: false,  // Bool to play live tv in browser or on Kodi
        player_component_backdrop: {},  // Image to show in the player
        player_component_title: {},  // Title to show in the player
        kodi_websocket: {},  // KodiWebsocket.js instance

        /* content related */
        content_filter: '',  // Hold a string for filtering on Movies, TVShows or TVChannels
        genre_filter: '',

        /* login related */
        isLoggedIn: false,
        token: null,
        username: null,
        isAdmin: false
    }),
    actions: {
        logout() {
            this.isLoggedIn = false
            this.username = null
            this.isAdmin = false
            this.token = null
        }
    }
})
