import {createRouter, createWebHistory} from 'vue-router'
import {useStore} from '@/store'

const routes = [
    // Base routes
    {
        path: '/login', component: () => import('@/components/Login'),
        name: 'login'
    },
    {
        path: '/', component: () => import('@/components/Home'),
        name: 'Home'
    },

    // Movies, TVShows and LiveTV
    {
        path: '/movies', component: () => import('@/components/Movies.vue'),
        name: 'Movies'
    },
    {
        path: '/tvshows', component: () => import('@/components/TVShows.vue'),
        name: 'TVShows'
    },
    {
        path: '/live_tv', component: () => import('@/components/LiveTV.vue'),
        name: 'LiveTV'
    },

    // User management
    {
        path: '/accounts', component: () => import('@/components/Accounts.vue'),
        name: 'Accounts'
    },
    {
        path: '/profile', component: () => import('@/components/Profile.vue'),
        name: 'Profile'
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

router.beforeEach((to, from, next) => {
    // Check if the user is logged in
    const store = useStore()
    const isLoggedIn = store.$state.isLoggedIn;

    // If the route requires authentication and the user is not logged in, redirect to the login page
    if ((to.name !== 'login' && !isLoggedIn)) {
        next({name: 'login'})
    } else {
        // Otherwise, allow the user to proceed to the requested location
        next()
    }
});

export default router

