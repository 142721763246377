import config from '@/config.js'
import {toast} from "vue3-toastify";
import "vue3-toastify/dist/index.css";

export function initializeWebSocket() {
    let ws = null;
    let ws_connected = false;

    function connect_ws() {
        let websocketURL = config.wsUrl;
        if (location.protocol === 'https:') {
            websocketURL = config.wssUrl;
        }
        ws = new WebSocket(websocketURL);

        ws.onopen = () => {
            ws.send('Connect')
            console.log("WebSocket connection is now open.");
            ws_connected = true;
        };

        ws.onmessage = (event) => {
            const data = JSON.parse(event.data);
            console.log('Received message:', data);
            const htmlString = "<div><h4>" + data.title + "</h4><p>" + data.message + "</p></div>"
            toast(htmlString, {
                "theme": "dark",
                "type": data.type,
                "autoClose": 2000,
                "transition": "flip",
                "dangerouslyHTMLString": true
            })
            // Handle incoming messages as needed
        }

        ws.onclose = (e) => {
            console.log('Socket is closed. Reconnect will be attempted in 1 second.', e.reason);
            setTimeout(() => {
                connect_ws();
            }, 1000);
        }

        ws.onerror = (err) => {
            console.error('Socket encountered error: ', err.message);
        }
    }

    return {
        connect_ws, ws, ws_connected
    };
}
