<template>
  <div
    v-if="store.show_player_component && isWindowWideEnough"
    ref="object"
    class="player-controller-container"
    :style="{ top: top + 'px', left: left + 'px' }"
  >
    <div
      class="content-title"
      @mousedown="startDrag"
      @mouseup="stopDrag"
    >
      <div class="content-title-center">
        {{ title }}
      </div>

      <div
        v-if="is_alive"
        style="color: greenyellow"
        class="content-title-span-right"
      >
        ●
      </div>
      <div
        v-if="!is_alive"
        style="color: red"
        class="content-title-span-right"
      >
        ●
      </div>
    </div>

    <div class="poster">
      <b-image
        :key="imageSrcKey"
        :src="imageSrc"
        :src-fallback="require('@/assets/anonymous-content-poster.png')"
        class="backdrop"
        lazy
      />
    </div>

    <div class="position-slider">
      <b-field>
        <b-slider
          v-model="position"
          type="is-black"
          :tooltip="false"
          @dragend="set_position()"
          @change="set_position()"
        />
      </b-field>
    </div>

    <div class="live-tv-switch">
      <b-switch
        v-model="store.live_tv_to_kodi"
        size="is-small"
        type="is-dark"
      />
    </div>
    <div
      v-if="store.live_tv_to_kodi"
      class="live-tv-text"
    >
      Live Tv on Kodi
    </div>
    <div
      v-if="!store.live_tv_to_kodi"
      class="live-tv-text"
    >
      Live Tv in browser
    </div>

    <div class="time">
      {{ time }}
    </div>
    <div class="total-time">
      {{ total_time }}
    </div>


    <div class="stop">
      <b-icon
        pack="fas"
        icon="stop"
        size="is-small"
        class="menu-icon"
        @click="stop_playback()"
      />
    </div>

    <div class="play-pause">
      <b-icon
        v-if="is_playing===true"
        pack="fas"
        icon="pause"
        size="is-small"
        class="menu-icon"
        @click="pause_playback()"
      />
      <b-icon
        v-if="is_playing===false"
        pack="fas"
        icon="play"
        size="is-small"
        class="menu-icon"
        @click="pause_playback()"
      />
    </div>

    <div
      v-if="audio_tracks.length > 1"
      class="next-audio-track"
    >
      <div>
        <b-select
          v-model="selected_audio"
          class="custom-b-select"
          size="is-small"
          @change="set_audio_track()"
        >
          <option
            v-for="audio_track in audio_tracks"
            :key="audio_track.index"
            :value="audio_track"
          >
            {{ audio_track.language }} - ch:{{ audio_track.channels }},{{ audio_track.codec }}
          </option>
        </b-select>
      </div>
      <div
        v-if="false"
      >
        <b-icon
          pack="fas"
          icon="comments"
          size="is-small"
          class="menu-icon"
        />
      </div>
    </div>

    <div
      v-if="subtitle_tracks.length > 1"
      class="next-subtitle-track"
    >
      <div>
        <b-select
          v-model="selected_subtitle"
          class="custom-b-select"
          size="is-small"
          @change="set_subtitle_track()"
        >
          <option
            v-for="subtitle_track in subtitle_tracks"
            :key="subtitle_track.index"
            :value="subtitle_track"
          >
            {{ subtitle_track.language }}
          </option>
        </b-select>
      </div>
      <div
        v-if="false"
      >
        <b-icon
          pack="fas"
          icon="language"
          size="is-small"
          class="menu-icon"
        />
      </div>
    </div>

    <div
      id="player-selector"
      class="player-selector"
    >
      <b-select
        v-model="selected_player"
        class="custom-b-select"
        size="is-small"
        @change="set_player()"
      >
        <option
          v-for="player in players"
          :key="player._id"
          :value="player"
        >
          {{ player.name }}
        </option>
      </b-select>
    </div>

    <div class="volume-icon">
      <b-field>
        <b-icon
          v-if="volume===0"
          pack="fas"
          icon="volume-off"
          size="is-small"
          class="menu-icon"
        />
        <b-icon
          v-if="volume > 0 && volume < 60"
          pack="fas"
          icon="volume-down"
          size="is-small"
          class="menu-icon"
        />
        <b-icon
          v-if="volume >= 60"
          pack="fas"
          icon="volume-up"
          size="is-small"
          class="menu-icon"
        />
      </b-field>
    </div>

    <div class="volume-slider">
      <b-field>
        <b-slider
          v-model="volume"
          type="is-black"
          :tooltip="false"
          @dragend="set_volume()"
          @change="set_volume()"
        />
      </b-field>
    </div>
  </div>
</template>

<script>

import {useStore} from "@/store";
import config from "@/config";
import {kodiEvents, KodiWebsocket} from "@/components/KodiWebsocket";
import {http} from "@/main";
import {toast} from "vue3-toastify";

export default {
  name: 'PlayerComponent',

  components: {},

  setup() {
    const store = useStore()
    return {store}
  },

  data() {
    return {
      kodi_websocket: null,
      players: [],
      selected_player: {'_id': 0},
      is_playing: true,
      title: 'Unknown content',
      volume: 50,
      position: 0,
      time: '00:00.00',
      total_time: '00:00.00',
      audio_tracks: [],
      selected_audio: null,
      subtitle_tracks: [],
      selected_subtitle: null,
      is_alive: false,
      dragging: false,
      top: 85,
      left: 20,
      startX: 100,
      startY: 100,
      isWindowWideEnough: true
    };
  },

  computed: {
    imageSrc() {
      // Use a computed property to generate the image source
      try {
        if (this.store.selected_player === null) return '';

        const player_name = this.store.selected_player.name;
        if (!this.store.player_component_backdrop.hasOwnProperty(player_name)) return '';
        if (this.store.player_component_backdrop['player_name'] === null) return '';

        if (this.store.player_component_backdrop[player_name].startsWith("http")) {
          return this.store.player_component_backdrop[player_name] + '?' + Date.now();
        }

        return config.apiUrl + '/images/' + this.store.player_component_backdrop[player_name] + '?' + Date.now();
      } catch (e) {
        return ''
      }
    },
    imageSrcKey() {
      // Use a computed property to generate a unique key for the image source
      // This will force re-rendering of the <b-image> component when the image source changes
      return this.imageSrc;
    }
  },

  watch: {
    'store.player_component_title': {
      handler(newValue, oldValue) {
        const player_name = this.store.selected_player.name
        this.title = this.store.player_component_title[player_name]
        if (this.store.player_component_title[player_name] === '') {
          this.title = 'Unknown content'
        }
      },
      deep: true // Watch for changes deeply within the object
    },

    'store.show_player_component'() {
      if (this.store.show_player_component) {
        this.set_player()
      }
    },

    'store.players_updated'() {
      this.load_players()
    }
  },

  mounted() {
    window.addEventListener('resize', this.handleResize);
    kodiEvents.addEventListener('kodi', this.handle_kodi_events)
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
    kodiEvents.removeEventListener('kodi', this.handle_kodi_events)
  },

  created() {
    this.load_players()
  },

  methods: {
    stop_playback: async function () {
      console.log('stop_playback')
      const player_name = this.selected_player.name
      this.store.player_component_backdrop[player_name] = ''
      this.store.player_component_title[player_name] = 'Unknown content'
      this.$nextTick(async () => {
        await this.kodi_websocket.stop()
      })
    },

    pause_playback: async function () {
      console.log('pause_playback')
      await this.kodi_websocket.play_and_pause()
      await this.kodi_websocket.get_streams()
    },

    set_audio_track: async function () {
      console.log('set_audio_track', this.selected_audio.language, this.selected_audio.index)
      await this.kodi_websocket.set_next_audio_track(this.selected_audio.index)
    },

    set_subtitle_track: async function () {
      console.log('set_subtitle_track', this.selected_subtitle.language)
      if (this.selected_subtitle.index === -1) {
        await this.kodi_websocket.set_next_subtitle_track(0, false)  // disable subtitle
      } else {
        await this.kodi_websocket.set_next_subtitle_track(this.selected_subtitle.index, true)
      }
    },

    set_volume: async function () {
      await this.kodi_websocket.set_volume(this.volume)
    },

    set_position: function () {
      this.$nextTick(async () => {
        await this.kodi_websocket.set_position(this.position)
      })
    },

    set_player: function () {
      this.use_player()
    },

    use_player: async function () {
      // Tear down old player
      this.is_alive = false
      this.selected_audio = null
      this.selected_subtitle = null
      this.audio_tracks = []
      this.subtitle_tracks = []
      try {
        await this.kodi_websocket.closeConnection()
        this.kodi_websocket = null
        this.time = '0:00:00'
        this.total_time = '0:00:00'
        this.position = 0
      } catch (e) {
      }

      // Initialize new player
      const {
        connect_ws,
        closeConnection,
        play,
        play_and_pause,
        stop,
        set_position,
        get_playback_info,
        get_streams,
        set_volume,
        get_volume,
        get_currently_playing,
        set_next_audio_track,
        set_next_subtitle_track
      } = KodiWebsocket(this.selected_player)

      this.kodi_websocket = {
        connect_ws,
        closeConnection,
        play,
        play_and_pause,
        stop,
        set_position,
        get_playback_info,
        get_streams,
        set_volume,
        get_volume,
        get_currently_playing,
        set_next_audio_track,
        set_next_subtitle_track
      }

      this.store.kodi_websocket = this.kodi_websocket
      this.store.selected_player = this.selected_player
      connect_ws(this.selected_player)

      const player_name = this.store.selected_player.name
      if (this.store.player_component_title.hasOwnProperty(player_name)) {
        this.title = this.store.player_component_title[player_name]
        if (this.store.player_component_title[player_name] === '') {
          this.title = 'Unknown content'
        }
      }
    },

    handle_kodi_events: function (event) {
      const data = event.detail
      switch (data.player) {
        case 'play':
          this.is_playing = true
          break;

        case 'pause':
          this.is_playing = false
          break;

        case 'last_seen':
          this.is_alive = Date.now() - data.date <= 3
          break;

        case 'stream_details':
          const default_subtitle_track = [{index: -1, language: 'Off'}]
          const indexed_subtitles = data.details.subtitle.map((subtitle, index) => {
            return {
              index: index,
              language: subtitle.language
            }
          })
          this.subtitle_tracks = default_subtitle_track.concat(indexed_subtitles)
          if (this.selected_subtitle === null) this.selected_subtitle = this.subtitle_tracks[0]

          this.audio_tracks = data.details.audio.map((audio, index) => {
            return {index: index, channels: audio.channels, codec: audio.codec, language: audio.language}
          })
          if (this.selected_audio === null) this.selected_audio = this.audio_tracks[0]
          break;

        case 'media_info':
          const media_info = data.media_info
          try {
            this.position = media_info.percentage
            // Format time
            this.time =
                media_info.time.hours + ":" +
                (media_info.time.minutes < 10 ? "0" + media_info.time.minutes : media_info.time.minutes) + "." +
                (media_info.time.seconds < 10 ? "0" + media_info.time.seconds : media_info.time.seconds);

            // Format total time
            this.total_time =
                media_info.totaltime.hours + ":" +
                (media_info.totaltime.minutes < 10 ? "0" + media_info.totaltime.minutes : media_info.totaltime.minutes) + "." +
                (media_info.totaltime.seconds < 10 ? "0" + media_info.totaltime.seconds : media_info.totaltime.seconds);
          } catch (e) {
          }
          break;

        default:
          console.log('Received unhandled kodi event:', data.player);
          break;
      }
    },

    load_players: function () {
      const url = '/kodi'
      http.get(url)
          .then(response => {
            this.players = response.data
            if (response.data.length > 0) {
              this.selected_player = this.players[0]
              this.store.has_players = true
              this.use_player()
            } else {
              this.store.has_players = false
              this.store.show_player_component = false
            }
          })
          .catch(error => {
            toast(error.response.data.detail, {
              "theme": "dark",
              "type": "warn",
              "autoClose": 2000,
              "transition": "flip",
              "dangerouslyHTMLString": true
            })
          })
    },

    handleResize: function () {
      this.left = 100
      this.top = 100
      this.isWindowWideEnough = window.innerWidth >= 768
    },

    startDrag: function (event) {
      this.dragging = true;
      this.startX = event.clientX - this.$refs.object.getBoundingClientRect().left;
      this.startY = event.clientY - this.$refs.object.getBoundingClientRect().top;
      document.addEventListener('mousemove', this.dragObject);
    },

    stopDrag: function () {
      this.dragging = false;
      document.removeEventListener('mousemove', this.dragObject);
    },

    dragObject: function (event) {
      if (!this.dragging) return;
      this.left = event.clientX - this.startX;
      this.top = event.clientY - this.startY;
    }
  }
}
</script>


<style scoped lang="scss">
$amber: rgba(245, 172, 12, 0.99);
$width: 340px;
$row-height: 10px;
$row-height-mobile: 60px;
$poster-height: 450px;
$poster-width: 300px;
$min-desktop-width: 769px;
$max-mobile-width: 768px;

@media (min-width: $min-desktop-width) {
  .player-controller-container {
    z-index: 100;
    display: grid;
    max-width: $width;
    align-self: center;
    justify-self: center;
    background-color: whitesmoke;
    border-radius: 15px;
    grid-template-columns: repeat(34, calc($width / 34));
    grid-template-rows: repeat(30, $row-height);
    gap: 0 0;
    grid-auto-flow: row;
    position: fixed;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.9); /* Adjust the values as needed */
  }

  .content-title {
    cursor: move;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    z-index: 11;
    grid-area: 1 / 1 / 4 / 35;
    color: white;
    display: flex;
    justify-content: space-between; /* Ensure the title and dot are spaced apart */
    align-items: center;
    font-weight: bold;
    font-size: small;
    background-color: rgba(25, 13, 13, 0.75);
  }

  .content-title-center {
    flex: 1; /* Allow the center title to take up remaining space */
    text-align: center;
  }

  .content-title-span-right {
    margin-left: auto; /* Push the dot to the end */
    padding-right: 10px; /* Optional: add some padding to the right for spacing */
    font-size: 25px;
  }

  .poster {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    grid-area: 1 / 1 / 20 / 35;
    margin: 0;
    background-color: black;
    overflow: hidden;

    .backdrop {
      margin: 0;
      width: 100%;
      height: 100%;
    }
  }

  .live-tv-switch {
    grid-area: 24 / 10 / 25 / 13;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .live-tv-text {
    grid-area: 26 / 10 / 27 / 20;
    font-size: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: -2px;
  }

  .position-slider {
    grid-area: 26 / 2 / 27 / 22;

    .position-b-slider {
      border-radius: 0 !important;
    }
  }

  .time {
    grid-area: 29 / 2 / 30 / 15;
    margin-top: 5px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 10px;
  }

  .total-time {
    margin-top: 5px;
    grid-area: 29 / 18 / 30 / 22;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 10px;
  }

  .player-selector {
    grid-area: 21 / 1 / 22 / 13;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-color: transparent;
    outline: none !important;
    outline-color: transparent;
    margin-right: 5px;

    ::v-deep(select) {
      background-color: transparent;
      border: none;
      cursor: pointer;
      outline: none !important;
      outline-color: transparent;
      text-align: end;
    }

    ::v-deep(contol.span) {
      background-color: transparent;
      border: none;
      cursor: pointer;
      outline: none !important;
      outline-color: transparent;
      text-align: end;
    }
  }

  .stop {
    grid-area: 24 / 2 / 25 / 4;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .play-pause {
    grid-area: 24 / 6 / 25 / 8;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .next-audio-track {
    grid-area: 21 / 20 / 22 / 35;
    cursor: pointer;
    border-color: transparent;
    outline: none !important;
    outline-color: transparent;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    ::v-deep(select) {
      background-color: transparent;
      border: none;
      cursor: pointer;
      outline: none !important;
      outline-color: transparent;
      text-align: end;
    }

    ::v-deep(contol.span) {
      background-color: transparent;
      border: none;
      cursor: pointer;
      outline: none !important;
      outline-color: transparent;
      text-align: end;
    }
  }

  .next-subtitle-track {
    grid-area: 24 / 20 / 25 / 35;
    cursor: pointer;
    border-color: transparent;
    outline: none !important;
    outline-color: transparent;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    ::v-deep(select) {
      background-color: transparent;
      border: none;
      cursor: pointer;
      outline: none !important;
      outline-color: transparent;
      text-align: end;
    }

    ::v-deep(select) {
      background-color: transparent;
      border: none;
      cursor: pointer;
      outline: none !important;
      outline-color: transparent;
      text-align: end;
    }

    ::v-deep(.control.span) {
      background-color: transparent;
      border: none;
      cursor: pointer;
      outline: none !important;
      outline-color: transparent;
      text-align: end;
    }
  }

  .volume-icon {
    grid-area: 27 / 24 / 28 / 26;
    margin-right: 3px;
  }

  .volume-slider {
    margin-top: 2px;
    grid-area: 26 / 26 / 27 / 34;
  }
}
</style>