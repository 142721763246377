<template>
  <div class="wrapper">
    <b-navbar
      class="b-navbar"
      fixed-top
    >
      <template #brand>
        <b-navbar-item
          :to="{ path: '/' }"
          tag="router-link"
        >
          <img
            alt="Statistics"
            src="@/assets/ribbon-logo.png"
          >
        </b-navbar-item>
      </template>

      <template #start>
        <!-- MOVIES -->

        <div class="menu-items">
          <b-navbar-item
            class="is-active margin-left"
            tag="router-link"
            to="/movies"
          >
            Movies
          </b-navbar-item>
        </div>

        <!-- TVSHOWS -->

        <div class="menu-items">
          <b-navbar-item
            class="is-active margin-left"
            tag="router-link"
            to="/tvshows"
          >
            TVShows
          </b-navbar-item>
        </div>

        <!-- LIVE-TV -->

        <div class="menu-items">
          <b-navbar-item
            class="is-active margin-left"
            tag="router-link"
            to="/live_tv"
          >
            Live TV
          </b-navbar-item>
        </div>

        <!-- SEPARATOR -->

        <div
          v-if="$route.name !== 'Home' && isWindowWideEnough && showFilterRoutes.includes($route.name)"
          class="divider"
        >
          <span class="divider-span" />
        </div>

        <div
          v-if="showFilterRoutes.includes($route.name)"
          class="filter"
        >
          <b-field>
            <b-input
              v-model="store.$state.content_filter"
              type="text"
              placeholder="Content filter"
              icon-pack="fas"
              icon-right="search"
              icon-right-clickable
            />
          </b-field>
        </div>
      </template>

      <!-- Login icons -->

      <template #end>
        <b-navbar-item
          v-if="store.show_player_component===false && store.has_players === true"
          class="is-active margin-left"
          @click="player_controls()"
        >
          Show player
        </b-navbar-item>
        <b-navbar-item
          v-if="store.show_player_component===true && store.has_players === true"
          class="is-active margin-left"
          @click="player_controls()"
        >
          Hide player
        </b-navbar-item>
        <b-navbar-dropdown
          label="User"
          right
          collapsible
        >
          <span class="user-name">
            <b-icon
              pack="fas"
              icon="user"
              size="is-small"
              class="menu-icon"
            />
            Logged in as: <b>{{ store.$state.username }}</b>
          </span>

          <hr>

          <b-navbar-item>
            <div class="external_link">
              <b-icon
                pack="fas"
                icon="code"
                size="is-small"
                class="menu-icon"
              />
              <a
                :href="config.apiDocs"
                style="color: #4a4a4a;"
                class="btn-link"
              >API docs</a>
            </div>
          </b-navbar-item>

          <b-navbar-item
            v-if="store.$state.isAdmin"
            tag="router-link"
            to="/accounts"
          >
            <b-icon
              pack="fas"
              icon="user-check"
              size="is-small"
              class="menu-icon"
            />
            Account Manager
          </b-navbar-item>

          <b-navbar-item
            v-if="!store.$state.isAdmin"
            tag="router-link"
            to="/profile"
          >
            <b-icon
              pack="fas"
              icon="user-check"
              size="is-small"
              class="menu-icon"
            />
            Profile
          </b-navbar-item>

          <b-navbar-item @click.native="logout">
            <b-icon
              pack="fas"
              icon="user-lock"
              size="is-small"
              class="menu-icon"
            />
            Logout
          </b-navbar-item>
        </b-navbar-dropdown>
      </template>
    </b-navbar>
  </div>
</template>

<script>
import {http} from '@/main'
import router from "@/router";
import {useStore} from "@/store";

export default {
  name: 'Navigation',
  components: {},

  setup() {
    const store = useStore()
    return {store}
  },

  data() {
    return {
      has_selected_content: 1,
      current_route: router.currentRoute.value.name,
      isWindowWideEnough: true,
      showFilterRoutes: ['Movies', 'TVShows', 'LiveTV'],
      show_player_component: false
    }
  },

  mounted() {
    window.addEventListener('resize', this.handleResize);
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },

  created() {
  },

  methods: {
    handleResize: function () {
      this.isWindowWideEnough = window.innerWidth > 1023
    },

    player_controls: function () {
      this.store.show_player_component = !this.store.show_player_component
    },

    logout: function () {
      this.store.logout()
      http.defaults.headers.common['Authorization'] = ''
      router.push('/login')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/css/navigation_component';
</style>
