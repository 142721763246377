<template>
  <div id="app">
    <div
      id="app-content"
      class="content"
    >
      <navigation
        v-if="store.$state.isLoggedIn === true"
        class="navigation"
      />
      <router-view class="container" />

      <component
        :is="player_component"
        v-if="player_component"
        @close="detachPlayerComponent"
      />
    </div>
  </div>
</template>

<script>
import Navigation from '@/components/Navigation'
import {useStore} from '@/store'
import PlayerComponent from "@/components/Player_component.vue";

export default {
  name: 'App',
  components: {PlayerComponent, Navigation},
  setup() {
    const store = useStore()
    return {
      store
    }
  },
  data() {
    return {
      isLoggedIn: false, // Flag to track login status
      player_component: null // Dynamically attach player component after login
    };
  },
  watch: {
    'store.$state.isLoggedIn'() {
      if (this.store.$state.isLoggedIn) {
        this.attachPlayerComponent()
      } else {
        this.detachPlayerComponent()
      }
    }
  },
  methods: {
    attachPlayerComponent() {
      this.player_component = PlayerComponent;
      console.log('Attached player component')
    },
    detachPlayerComponent() {
      this.player_component = null;
      this.isLoggedIn = false;
      console.log('Detached player component')
    }
  }
}
</script>

<style lang="scss">
@import 'assets/css/app.scss';
</style>
