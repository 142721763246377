import App from './App.vue'
import {createApp} from 'vue'
import {pinia, useStore} from '@/store'
import axios from 'axios'
import router from '@/router'
import Buefy from '@ntohq/buefy-next'
import 'bootstrap-icons/font/bootstrap-icons'
import config from '@/config.js'
import {initializeWebSocket} from '@/components/websocket'

/* import font awesome icon component */
import {library} from "@fortawesome/fontawesome-svg-core";
import {
    faBackspace,
    faBroadcastTower,
    faCheck,
    faChevronRight,
    faCode,
    faComments,
    faExclamation,
    faFilm,
    faFilter,
    faLanguage,
    faPause,
    faPlay,
    faPlus,
    faSearch,
    faSignInAlt,
    faStop,
    faTowerBroadcast,
    faTv,
    faUser,
    faVolumeDown,
    faVolumeHigh,
    faVolumeLow,
    faVolumeMute,
    faVolumeOff,
    faXmarksLines
} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'


library.add(faBackspace, faBroadcastTower, faCheck, faChevronRight, faCode, faComments, faExclamation, faFilm, faFilter, faLanguage, faPause, faPlay, faPlus, faSearch, faSignInAlt, faStop, faTowerBroadcast, faTv, faUser, faVolumeHigh, faVolumeLow, faVolumeMute, faVolumeOff, faVolumeDown, faXmarksLines
)

const app = createApp(App).use(pinia).use(router)

app.component('FontAwesomeIcon', FontAwesomeIcon)

export const http = axios.create({
    // Create an instance of Axios
    baseURL: config.apiUrl,
    headers: {
        Authorization: null
    }
})

// Add a request interceptor
app.config.productionTip = false
app.use(Buefy)

// setup websocket
const {connect_ws, ws, ws_connected} = initializeWebSocket()
if (ws === null && ws_connected === false) {
    connect_ws()
}

app.mixin({
    router,

    setup() {
        // setup store
        const store = useStore()
        return {store}
    },

    data: function () {
        return {
            toast: '',
            config: config
        }
    },

    beforeCreate() {
    },

    created() {
        http.interceptors.response.use(
            response => response,
            (error) => {
                try {
                    if (error.response.status === 401) {
                        const store = useStore()
                        store.logout()
                        router.push({path: '/', replace: true}).then()
                    }
                    return Promise.reject(error)
                } catch (error) {
                    return Promise.reject(error)
                }
            }
        )

        http.interceptors.request.use(
            function (config) {
                // Set the Authorization header before sending the request
                const token = localStorage.getItem('access_token')
                if (token) {
                    config.headers.Authorization = `Bearer ${token}`
                }
                return config
            },
            function (error) {
                // Handle request errors
                return Promise.reject(error)
            }
        )
    },

    methods: {},

    render: h => h(App)
})

app.mount('#app')
